.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
@keyframes pulse {
  0%,
  100% {
    box-shadow: 0 0 8px rgba(255, 255, 255, 0);
    border-color: rgba(255, 255, 255, 0);
  }
  50% {
    box-shadow: 0 0 20px rgba(255, 255, 255, 1);
    border-color: rgba(255, 255, 255, 1);
  }
}

/* @keyframes fadeBackground {
  0%, 100% {
    background-color: transparent;
  }
  15% {
    background-color: #f44336;
  }
  25% {
    background-color: #f44336;
  }
  55% {
    background-color: #40c9b5;
  }
  65% {
    background-color: #40c9b5;
  }
  90% {
    background-color: transparent;
  }
} */

.elementWithGradient {
  position: relative;
  overflow: hidden;
}

.elementWithGradient::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #9400d3, #24437c);
  opacity: 0;
  transition: opacity 4s ease-in-out;
  z-index: -1;
}

/* Adjust the timing for the fade-in and fade-out transitions */
.elementWithGradient.fade-in::before {
  animation: fadeBackground 4s ease-in-out forwards; /* Use forwards to hold the final state at the end of animation */
}

@keyframes fadeBackground {
  0%,
  16.67% {
    /* 0ms to ~500ms */
    opacity: 0;
  }
  16.67%,
  80% {
    /* ~500ms to 1500ms (500ms to fully appear and hold until 1500ms) */
    opacity: 1;
  }
  80%,
  100% {
    /* 1500ms to 3000ms (starts fading out slowly from 1500ms to 3000ms) */
    opacity: 0;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
